<template>
	<div class="home" v-scroll="handleScroll">
		<intro @click-scroll-down="scrollTo('shop')"/>
		<!--<about id="story"/>
		<history/>
		<laurent/>
		<collections :collections="collections" :flavours="flavours" id="flavours"/>
		<section class="section&#45;&#45;padding"><category-list :items="categories"/></section>
		<corporate-orders id="special"/>
		<partnership id="partnership"/>
		<corners id="stores"/>
		<contacts id="contacts"/>-->

		<section id="shop" class="section--padding">
			<category-list :items="categories"/>
		</section>
		<collections id="flavours" :collections="collections" :flavours="flavours"/>
		<!--<about id="story"/>-->
		<history id="story"/>
		<history2/>
		<laurent/>
		<corporate-orders id="corporate"/>
		<partnership id="partnership"/>
		<corners id="stores"/>
		<contacts id="contacts"/>
	</div>
</template>

<script>
	import Intro from './Home/intro';
	import About from './Home/about';
	import History from './Home/history';
	import History2 from './Home/history2';
	import Laurent from './Home/laurent';
	import Partnership from './Home/partnership';
	import Collections from '@/components/collections';
	import CategoryList from '@/components/category-list';
	import CorporateOrders from './Home/corporate-orders';
	import Contacts from './Home/contacts';
	import Corners from './Home/corners';
	import {scrollTo} from '@/utils/utils';

	export default {
		name: "Home",
		components: {
			Intro,
			About,
			History,
			History2,
			Laurent,
			Collections,
			Partnership,
			CategoryList,
			CorporateOrders,
			Contacts,
			Corners
			// yandexMap, ymapMarker
		},
		data() {
			return {
				//categories
			}
		},
		computed: {
			categories() {
				return this.$store.state.page.HomeController.categories;
			},
			collections() {
				return this.$store.state.page.HomeController.collections;
			},
			flavours() {
				return this.$store.state.page.HomeController.flavours;
			}
		},

		methods: {
			handleScroll() {
				// set anchor
				let anchor = null;
				const ids = ["shop", "flavours", "story", "corporate", "stores", "partnership", "contacts"];
				ids.forEach(id=>{
					const el = document.getElementById(id);
					if ( !el ) return;
					const rect = el.getBoundingClientRect();
					if (rect.top <= 0) anchor = id;
				});
				const path = window.location.href.replace(/^(.*?)(#.+)/, "$1");
				let currentAnchor = window.location.href.replace(/^.*?#(.+)/, "$1");
				if ( currentAnchor === path ) currentAnchor = null;
				if ( anchor && anchor !== currentAnchor ) {
					//console.log("New anchor: "+anchor);
					window.history.pushState("", "", path+"#"+anchor);
					//this.$router.replace({path: "/#"+anchor, query:{forced:true}});
				}
				else if ( !anchor && currentAnchor ) {
					//console.log("New anchor: unset");
					//this.$router.replace({path: "/", query:{forced:true}});
					window.history.pushState("", "", path);
				}
			},
			scrollTo(id) {
				scrollTo(id);
			}
		}
	}
</script>

<style lang="scss">
	.home {
		.collections {
			background: $gray-lightest;
		}

		#shop {
			padding-bottom: 0;
			@include up($md) {
				padding-bottom: 32px;
			}
		}
	}
</style>