<template>
	<section class="section--padding text-center collections">
		<v-container class="mb-8">
            <h2>{{this.page.texts.HOME_OUR_PRODUCT.NAME}}</h2>
			<div class="manual color-black">{{this.page.texts.HOME_TAKE_A_LOOK.NAME}}</div>
        </v-container>

		<div class="d-flex justify-center mb-15 collections__header">
			<div class="d-flex align-center collections__header-items">
				<template v-for="(item,index) of collectionsWithAnchor">
					<a @click="onClick(item)" class="size2--u">{{item.NAME}}</a>
					<span v-if="index+1<collectionsWithAnchor.length" class="collections__header-dot"></span>
				</template>
			</div>
		</div>

		<div v-for="(collection, index) of collectionsNonEmpty" :id="'section'+collection.ID"
			 class="collections__item">
			<v-container>
				<h3 class="z-index-1 relative">{{ collection.NAME }}</h3>
				<h5 v-if="collection.UF_SUBTITLE" class="mt-2">{{ collection.UF_SUBTITLE }}</h5>

				<flavour-list :items="flavoursOfCollection(collection)"
							  :category="collection"
							  :products="products"
							  class="mt-12"
				/>
			</v-container>
		</div>
	</section>
</template>

<script>
	import Vue from "vue";
	import FlavourList from "@/components/flavour-list";
	import {scrollTo} from "@/utils/utils";

	export default {
		name: "collections",
		props: ["collections", "flavours"],
		data() {
			return {}
		},
		components: {
			FlavourList
		},
		computed: {
			flavoursProcessed() {
				return this.flavours.map(el => {
					if (el.MINI_ID && !el.mini) {
						const mini = this.flavours.find(m => m.ID === el.MINI_ID);
						if (mini) {
							Vue.set(mini, "parent", el);
							Vue.set(el, "mini", mini);
						}
					}
					return el;
				})
			},
			products() {
				return this.page.products;
			},
            page() {
                return this.$store.state.page.HomeController;
            },
			collectionsNonEmpty() {
				return this.collections?.filter(el => this.flavoursProcessed?.some(fl => fl.IBLOCK_SECTION_ID === el.ID || fl.SECTION_ID?.some(id=>id===el.ID)));
			},
			collectionsWithAnchor() {
				//console.log(this.collectionsNonEmpty);
				return this.collectionsNonEmpty?.filter(el => el.UF_WITH_ANCHOR==="1");
			}
		},
		methods: {
			flavoursOfCollection(collection) {
				return this.flavoursProcessed?.filter(fl => {
					return fl.IBLOCK_SECTION_ID === collection.ID || fl.SECTION_ID?.some(id=>id===collection.ID);
				});
			},
			onClick(item) {
				scrollTo("section"+item.ID, -170);
			}
		}
	}
</script>

<style lang="scss">
	.collections {
		position: relative;

		&__header {
			background: $gray-lightest;
			position: sticky;
			//left: 50%;
			top: 75px;
			//transform: translateX(-50%);
			z-index: 2;

			@include up($md) {
				top: 112px;
			}

			@include up($lg) {
				top: 120px;
			}

			&-items {
				padding: 14px 5%;
				border-bottom: 1px solid $primary-100;
				white-space: nowrap;
				width: 100%;
				flex-wrap: wrap;
				justify-content: center;

				@include up($md) {
					padding: 14px 0;
					width: unset;
					flex-wrap: nowrap;
				}


				a:not(.router-link-active) {
					color: $black !important;
					//font-weight: 300;
					line-height: 24px;
					@include up($md) {
						line-height: unset;
					}
				}
			}

			&-dot {
				width: 3px;
				height: 3px;
				border-radius: 100%;
				display: inline-block;
				background: $black-50;
				margin: 0 12px;
			}
		}

		&__item {
			margin-top: 12px;
			@include up($md) {
				margin-top: 48px;
			}
		}
	}
</style>