<template>
    <section class="section--padding text-center text-md-start history2">
        <v-container class="container--narrow">
            <v-row>
                <v-col cols="12" md="6" class="d-flex justify-center justify-md-start">
                    <animated-img :src="require('@/assets/home/history/h1.jpg')" alt="Candies"
                                  :reversed="false"
                                  class="my-8 mt-md-4 history2__img"/>
                </v-col>
                <v-col cols="12" md="6" class="pr-md-10 order-md-first d-flex justify-center justify-md-start align-md-center">
                    <div>
                        <span v-html="this.page.texts.HOME_SOFT_CUBE.TEXT_1"></span>
                        <!--<p class="limited">
                            Soft cube shape is a unique concept. It took us long time to work out the shape of pralines
                            trying to make the edges of the sweets soft and to keep such size, that the praline cube
                            could be placed in hand, its weight could be felt, and could become a manifestation of
                            feelings towards a loved one.
                        </p>
                        <p class="limited">
                            We take pride in selecting only the highest quality natural ingredients. We have created our
                            very own and unique basil-infused oil and make our very own delicious blend of caramel. Our
                            goal is to maintain the purest form of all our natural ingredients.
                        </p>-->
                    </div>
                </v-col>
            </v-row>
        </v-container>
    </section>
</template>

<script>
import AnimatedImg from "@/components/animated-img";

export default {
    name: "history2",
    components: {
        AnimatedImg
    },
    computed: {
        page() {
            return this.$store.state.page.HomeController;
        },
    },
}
</script>

<style lang="scss">
.history2 {
    background: $primary-50;
}
</style>